import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePlanId } from '../services/shopifyService';
import Loader from '../img/spinner.svg'

const UpdateChargePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        // Extract query parameters from the URL
        const urlParams = new URLSearchParams(location.search);
        const shop = urlParams.get('shop');
        const planId = urlParams.get('planId');
        const charge_id = urlParams.get('charge_id');

        if (shop) {
            updatePlanId(planId, charge_id, shop, navigate);
        } else {
            // Handle case where shop parameter is missing
            console.log('Shop parameter is missing in the URL');
        }
    }, [location.search]);

    return <div style={{margin:'auto', display:'flex',justifyContent:'center',alignItems:'center'}}><img style={{margin:'auto', marginTop: '20%'}} src={Loader} /></div>;
};

export default UpdateChargePage;
