import React from "react";

const PulseIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    aria-hidden="true"
    style={{
      verticalAlign: "middle",
      marginRight: "5px",
      display: "inline-block",
    }}
  >
    <circle
      cx="10"
      cy="10"
      r="5"
      fill={color}
      style={{
        animation: "inventory-pulse 2s linear infinite",
        transformOrigin: "center",
      }}
    />
    <circle cx="10" cy="10" r="5" stroke={color} fill={color} strokeWidth="1" />
    <style>
      {`
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
      `}
    </style>
  </svg>
);

const IconSelect = ({ icon, handleIconChange, disabled }) => {
  return (
    <select
      value={icon}
      onChange={(e) => handleIconChange(e.target.value)}
      style={{ width: "100px" }}
      className="select-like-input"
      disabled={disabled}
    >
      <option value="">Select Icon</option>
      <option
        value='<svg width="20" height="20" aria-hidden="true" class="custom-svg" style="vertical-align: middle; margin-right: 5px;display:inline-block !important">
<circle cx="10" cy="10" r="5" fill="rgb(255,69,0)" style="animation: inventory-pulse 2s linear infinite; transform-origin: center;"></circle>
<circle cx="10" cy="10" r="5" stroke="rgb(255,69,0)" fill="rgb(255,69,0)" stroke-width="1"></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>'
      >
        Red Pulse
      </option>
      <option
        value='<svg width="20" height="20" aria-hidden="true" class="custom-svg" style="vertical-align: middle; margin-right: 5px;display:inline-block !important">
<circle cx="10" cy="10" r="5" fill="rgb(238,148,65)" style="animation: inventory-pulse 2s linear infinite; transform-origin: center;"></circle>
<circle cx="10" cy="10" r="5" stroke="rgb(238,148,65)" fill="rgb(238,148,65)" stroke-width="1"></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>'
      >
        Orange Pulse
      </option>
      <option
        value='<svg width="20" height="20" aria-hidden="true" class="custom-svg" style="vertical-align: middle; margin-right: 5px;display:inline-block !important">
<circle cx="10" cy="10" r="5" fill="rgb(62, 214, 96)" style="animation: inventory-pulse 2s linear infinite; transform-origin: center;"></circle>
<circle cx="10" cy="10" r="5" stroke="rgb(62, 214, 96)" fill="rgb(62, 214, 96)" stroke-width="1"></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>'
      >
        Green Pulse
      </option>
      <option
        value='<svg width="20" height="20" aria-hidden="true" class="custom-svg" style="vertical-align: middle; margin-right: 5px;display:inline-block !important">
<circle cx="10" cy="10" r="5" fill="rgb(0,170,255)" style="animation: inventory-pulse 2s linear infinite; transform-origin: center;"></circle>
<circle cx="10" cy="10" r="5" stroke="rgb(0,170,255)" fill="rgb(0,170,255)" stroke-width="1"></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>'
      >
        Blue Pulse
      </option>

      <option value="📦">📦</option>

      <option value="🔴">🔴</option>

      <option value="🟠">🟠</option>

      <option value="🟢">🟢</option>

      <option value="❗️">❗️</option>

      <option value="👀">👀</option>

      <option value="😱">😱</option>

      <option value="😳">😳</option>

      <option value="🤯">🤯</option>

      <option value="🤗">🤗</option>

      <option value="😲">😲</option>

      <option value="🙀">🙀</option>

      <option value="👉🏼">👉🏼</option>

      <option value="👉🏾">👉🏾</option>

      <option value="🏷">🏷</option>

      <option value="🥁">🥁</option>

      <option value="📌">📌</option>
      <option value="🔺">🔺</option>
      <option value="🔊">🔊</option>
      <option value="🌪">🌪</option>
      <option value="💨">💨</option>
      <option value="⚡️">⚡️</option>
      <option value="✨">✨</option>
      <option value="💫">💫</option>
      <option value="🚨">🚨</option>
    </select>
  );
};

export default IconSelect;
