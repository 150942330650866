import React from "react";
import { useLocation, Navigate } from "react-router-dom";

const ShopifyAuth = ({ element }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const shop = params.get("shop");

  if (shop) {
    return element;
  } else {
  
    return <Navigate to="/unauthorized" replace />;
  }
};

export default ShopifyAuth;
