import axios from "axios";

const BACKEND_APP_HOST = process.env.REACT_APP_BACKEND_APP_HOST;



export const setCancelPlan = async (shop) => {
  try {
    const response = await axios.post(
      `${BACKEND_APP_HOST}/shopify/cancelPlan/?shop=${shop}`
    );
    return response;
  } catch (error) {
    console.error("Error returning data:", error);
  }
};

export const getCurrentPlan = async (shop) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/shopify/currentPlan/?shop=${shop}`
    );
    return response;
  } catch (error) {
    console.error("Error returning data:", error);
  }
};


export const getLowStockSettings = async (shop) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/api/stocklevels/${shop}`
    );
    return response;
  } catch (error) {
    console.error("Error returning data:", error);
  }
};

export const updateConditions = async (formattedData) => {
  try {
    const response = await axios.post(
      `${BACKEND_APP_HOST}/api/conditions`,
      formattedData
    );
    return response;
  } catch (error) {
    console.error("Error returning data:", error);
  }
};

export const getConditions = async (shop) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/api/conditions/${shop}`
    );
    return response;
  } catch (error) {
    console.error("Error returning data:", error);
  }
};

export const updateLowStockSettings = async (formattedData) => {
  try {
    const response = await axios.post(
      `${BACKEND_APP_HOST}/api/stocklevels`,
      formattedData
    );
    return response;
  } catch (error) {
    console.error("Error returning data:", error);
  }
};
