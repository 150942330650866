import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentPlan,setCancelPlan } from "../services/settingService";
import Swal from 'sweetalert2';


const CurrentPlan = ({ shop }) => {
	
	const [currentPlan, setCurrentPlan] = useState([]);
	const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCurrentPlan(shop);
        if (response.data && response.data) {
          setCurrentPlan(response.data.data);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
       
      }
    };

    fetchData();
  }, []);
	

  const handlePlan = async () => {
navigate(`/shopify/plan_view?shop=${shop}&upgrade=1`);
};	
	
	 const cancelPlan = async () => {
		 
		  Swal.fire({
      title: 'Are you sure?',
      text: "You want to cancel this current plan ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
     }).then(async (result) => {
      if (result.isConfirmed) {
         try {
        const response = await setCancelPlan(shop);
        if (response.data && response.data) {
          navigate(`/shopify/app_view?shop=${shop}&upgrade=1`);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
       
      }
        
      }
    });
		 
		 
		 
		 
		
	};
	
    return (
      
        <div className="card-container" style={{ 
          
                    borderRadius: "0.5rem",
                    backgroundColor: "#F2F2F7",
                    border: "2px solid #FF3B30",
                    padding: "0.5rem",
                    color: "#ffff",
        }}>


        
            <h4>{ currentPlan.plan_name } (Currently Active)</h4>
           <button  style={{  borderRadius: "0.5rem", backgroundColor: "#900", color: "#fff", marginBottom:"10px" }} onClick={cancelPlan}>Cancel Plan</button>
          <button  style={{  borderRadius: "0.5rem" }} onClick={handlePlan}>Change Plan</button>
        </div>
      
    );

};

export default CurrentPlan;
