import axios from "axios";

const API_URL = process.env.REACT_APP_HOST;
const BACKEND_APP_HOST = process.env.REACT_APP_BACKEND_APP_HOST;

export const initiateShopifyAuth = (shop) => {
  const state = generateRandomState();
  const installUrl = `https://${shop}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_API_KEY}&scope=read_online_store_pages,read_content,read_inventory,read_metaobjects,write_metaobjects,read_products&state=${state}&redirect_uri=${API_URL}/shopify/callback`;
  console.log("installUrl", installUrl);
  window.top.location.href = installUrl;
};

export const checkTokenAndRedirect = async (shop, navigate, currentParams) => {
  try {
    console.log(BACKEND_APP_HOST);
    const response = await axios.get(
      `${BACKEND_APP_HOST}/shopify/check-token${currentParams}`
    );
    if (response.data.hasToken) {
      navigate(`/shopify/app_view${currentParams}`);
    } else {
      initiateShopifyAuth(shop);
    }
  } catch (error) {
    console.error("Error checking token:", error);
  }
};

export const fetchPlans = async () => {
  try {
    const response = await axios.get(`${BACKEND_APP_HOST}/shopify/get-plans`);
    return response.data;
  } catch (error) {
    console.error("Error fetching plans:", error);
  }
};

export const fetchShopDetail = async (shopUrl) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/shopify/get-shop-detail?shop=${shopUrl}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching plans:", error);
  }
};

export const fetchProducts = async (shopUrl, keyword) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/shopify/products/${shopUrl}?keyword=${keyword}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

export const fetchCollections = async (shopUrl, keyword) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/shopify/collections/${shopUrl}?keyword=${keyword}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching collections:", error);
  }
};

export const setPlan = async (planId, shopUrl) => {
  try {
    const response = await axios.post(`${BACKEND_APP_HOST}/shopify/set-plans`, {
      planId,
      shopUrl,
    });
    return response;
  } catch (error) {
    console.error("Error setting plan:", error);
  }
};

export const updatePlanId = async (planId, charge_id, shopUrl, navigate) => {
  try {
    const response = await axios.post(
      `${BACKEND_APP_HOST}/shopify/update-plan`,
      {
        planId,
        charge_id,
        shopUrl,
      }
    );
    if (response.data.success) {
      //window.location.href = `https://${shopUrl}/admin/apps/${process.env.REACT_APP_SHOPIFY_API_KEY}`;
      navigate(`/shopify/setting_view?shop=${shopUrl}`);
    }
  } catch (error) {
    console.error("Error setting plan:", error);
  }
};

export const checkPlanAndRedirect = async (shop, navigate, currentParams) => {
  try {
    const response = await axios.get(
      `${BACKEND_APP_HOST}/shopify/get-shop-detail?shop=${shop}`
    );

    if (response.data.data && !response.data.data.plan_id) {
      navigate(`/shopify/plan_view${currentParams}`);
    } else {
      navigate(`/shopify/setting_view${currentParams}`);
    }
  } catch (error) {
    console.error("Error checking token:", error);
  }
};

const generateRandomState = () => {
  return [...Array(16)]
    .map(() => Math.floor(Math.random() * 36).toString(36))
    .join("");
};
