import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const HowToUseBlock = () => {
	
	const navigate = useNavigate();
	const currentParams = window.location.search;
	  const handleGuide = async () => {
navigate(`/shopify/guide_view${currentParams}`);
};	
	
	
  return (
   <div className="card-container" style={{ 
          
                    borderRadius: "0.5rem",
                    backgroundColor: "#F2F2F7",
                    border: "2px solid #FF3B30",
                    padding: "0.5rem",
                    color: "#ffff",
        }}>
       <button  style={{  borderRadius: "0.5rem" }} onClick={handleGuide}>Check our How to use Guide From Here</button>
        </div>
  );
};

export default HowToUseBlock;