import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../HowToUse.css';
import howTo from '../img/howto.png';
import saveBtn from '../img/save_btn.png';
import embedApp from '../img/embedapp.png';

const HowToUse = () => {
	 const location = useLocation();
	const navigate = useNavigate();
	
	const handleSetting = async () => {
		  
		const urlParams = new URLSearchParams(location.search);
   		 const shop = urlParams.get('shop');
			navigate(`/shopify/app_view?shop=${shop}`);
		};
	
	
  return (
    <div className="how-to-use">
	  
	   <button  style={{  borderRadius: "0.5rem", padding:"10px", marginTop:"20px" }} onClick={handleSetting}>Back to Setting</button>

      

      <section className="app-settings">
        <h1>App Admin Settings</h1>
        <p>
          <strong style={{  color: "teal" }}>Settings And Conditions</strong>
        </p>
        <p>Configure this section:</p>
        <ul>
          <li>Inventory Sources</li>
          <li>Display Widget On (Set on which products to work)</li>
          <li>Widget Position</li>
        </ul>
        <p>
          After enabling and setting all the above steps, click the <strong>‘Save Settings’</strong> button.
        </p>
        <p>
          <em>Note: Click ‘Advance Settings’ to check more optional settings.</em>
        </p>
<hr/>
      </section>

      <section className="storefront-settings">
        <h1>StoreFront Settings / App Embed Block</h1>
        <p>
          Go to your themes, then click <strong>Customize</strong>.<br/>
		<img  src={howTo} /><br/>
		 Now click on the <strong>App embed</strong> icon.
        </p>
        <p>
          After clicking, you will see a list of available App embed blocks. You will see our App embed block 
          <strong> ‘Urgency+ Low Stock Counter’</strong>, click on the toggle button and enable it.
<br/>
			<img  src={embedApp} /><br/>
        </p>
        <p>Click <strong>Save</strong> from the top right of the Customizer page.</p>
			<hr/>
      </section>


      <section className="hide-app">
        <h1>How to Hide the App from the Store</h1>
        <p>
			<img  src={saveBtn} /><br/>
          Go back again to App embed blocks, simply disable the toggle, and click <strong>Save</strong> from the 
          top right of the Customizer page.
        </p>

<hr/>
      </section>
    </div>
  );
};

export default HowToUse;