// InstallPage.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkPlanAndRedirect } from '../services/shopifyService';
import Loader from '../img/spinner.svg'

const AppViewPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        // Extract query parameters from the URL
        const urlParams = new URLSearchParams(location.search);
        const shop = urlParams.get('shop');
		const currentParams = window.location.search;

        if (shop) {
            checkPlanAndRedirect(shop, navigate,currentParams);
        } else {
            // Handle case where shop parameter is missing
            console.log('Shop parameter is missing in the URL');
        }
    }, [location.search]);

    return <div style={{ display:'flex',justifyContent:'center',alignItems:'center'}}><img style={{margin:'auto', marginTop: '20%'}} src={Loader} /></div>;
};

export default AppViewPage;
