import "../App.css";
import { useState, useEffect } from "react";
import StockRange from "../components/StockRange";
import StockCart from "../components/StockCart";
import { useLocation } from "react-router-dom";
import { getLowStockSettings } from "../services/settingService";
import Conditions from "../components/Conditions";
import ShopifyAppBridge from "../components/ShopifyAppBridge";
import FakeStock from "./FakeStock";
import CurrentPlan from "./CurrentPlan";
import HowToUseBlock from "./HowToUseBlock";
import Loader from "../img/spinner.svg";
import { fetchShopDetail } from "../services/shopifyService";

function SettingViewPage() {
  const [isVisible, setIsVisible] = useState(false);
  const [lessStock, setLessStock] = useState(10);
  const [moreStock, setMoreStock] = useState(20);
  const [otherInventory, setOtherInventory] = useState(false);
  const [toggle, setToggle] = useState({
    lessStockToggle: false,
    moreStockToggle: false,
  });

  const [shopDetail, setShopDetail] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const shop = urlParams.get("shop");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchShopDetail(shop);
        console.log("ss", res.data);
        setShopDetail(res.data);
        const response = await getLowStockSettings(shop);
        console.log("Data retrieved successfully:", response.data);

        if (response.data && response.data.length > 0) {
          const mergedData = mergeWithHardcodedData(response.data);
          setData(mergedData);
        } else {
          setHardcodedData();
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
        setHardcodedData();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // Function to merge API data with hardcoded fields
  const mergeWithHardcodedData = (apiData) => {
    const hardcodedFields = [
      {
        icon: "",
        text: "<strong>Continue Selling</strong> When Out of Stock",
        stock: "<=0",
        value: "",
        stocklevel: "stocklevelzero",
      },
      {
        icon: "",
        text: "Message When <strong> Out of Stock</strong>",
        stock: "Out of Stock",
        value: "",
        stocklevel: "stockleveloutofscope",
      },
      {
        icon: "",
        text: "Message When <strong>Stock=1</strong>",
        stock: 1,
        value: "1",
        stocklevel: "stocklevelone",
      },
      {
        icon: "",
        text: "<strong>Low in Stock</strong> Message",
        stock: "(Less Than)",
        value: "10",
        stocklevel: "stocklevelless",
      },
      {
        icon: "",
        text: "<strong>More in Stock</strong> Message",
        stock: "(More Than)",
        value: "20",
        stocklevel: "stocklevelmore",
      },
    ];

    return hardcodedFields.map((hardcoded, index) => {
      const apiItem = apiData[index];

      let premessage = "";
      let postmessage = "";

      if (apiItem?.stocklevel === "stocklevelless" && apiItem?.message) {
        const [pre, post] = apiItem.message.split("#");
        premessage = pre || "";
        postmessage = post || "";
      }

      return {
        ...hardcoded,
        icon: apiItem?.icon || hardcoded.icon,
        value: apiItem?.value || hardcoded.value,
        color: apiItem?.color || hardcoded.color,
        size: apiItem?.size || hardcoded.size,
        message: apiItem?.message || hardcoded.message,
        toggle: apiItem?.status == 1,
        ...(apiItem?.stocklevel === "stocklevelless" && {
          premessage,
          postmessage,
        }),
      };
    });
  };

  const setHardcodedData = () => {
    setData([
      {
        color: "black",
        size: 16,
        message: "Shipment in 7 days",
        icon: "",
        text: "<strong>Continue Selling</strong> When Out of Stock",
        stock: "<=0",
        toggle: false,
        value: "",
        stocklevel: "stocklevelzero",
      },
      {
        color: "black",
        size: 16,
        message: "Out of Stock but will be replenished soon",
        icon: "",
        text: "Message When <strong> Out of Stock</strong>",
        stock: "Out of Stock",
        toggle: false,
        value: "",
        stocklevel: "stockleveloutofscope",
      },
      {
        color: "black",
        size: 16,
        message: "This is the last stock, please hurry up",
        icon: "",
        text: "Message When <strong>Stock=1</strong>",
        stock: 1,
        toggle: false,
        value: "1",
        stocklevel: "stocklevelone",
      },
      {
        color: "black",
        size: 16,
        message: "Only # in stock",
        premessage: "Only",
        postmessage: "in stock",
        icon: "",
        text: "<strong>Low in Stock</strong> Message",
        stock: "(Less Than)",
        toggle: false,
        value: "10",
        stocklevel: "stocklevelless",
      },
      {
        color: "black",
        size: 16,
        message: "Stock is available and ready to ship",
        icon: "",
        text: "<strong>More in Stock</strong> Message",
        stock: "(More Than)",
        toggle: false,
        value: "20",
        stocklevel: "stocklevelmore",
      },
    ]);
  };

  return (
    <>
      {!isVisible && (
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ margin: "auto", marginTop: "20%" }} src={Loader} />
        </div>
      )}
      <div style={{ display: isVisible ? "block" : "none" }}>
        <div className="App">
          <div className="left-side" style={{ border: "2px solid #FF3B30" }}>
            <StockRange
              datas={data}
              shop={shop}
              setDatas={setData}
              setLessStock={setLessStock}
              setMoreStock={setMoreStock}
              lessStock={lessStock}
              moreStock={moreStock}
              toggle={toggle}
              setToggle={setToggle}
              otherInventory={otherInventory}
              shopDetail={shopDetail}
            />
          </div>

          <div className="right-side">
            <StockCart datas={data} otherInventory={otherInventory} />
          </div>
        </div>
        <div className="App">
          <div className="left-side" style={{ border: "2px solid #FF3B30" }}>
            <Conditions shop={shop} setOtherInventory={setOtherInventory} />
          </div>

          <div className="right-side">
            {" "}
            <CurrentPlan shop={shop} /> <HowToUseBlock shop={shop} />{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingViewPage;
