const StockCart = ({ datas,  otherInventory }) => {
  const computeMessage = (value) => {
    if (value.stocklevel === "stocklevelless") {
      let lessNo = parseInt(value.value) - 1;
      return `${value.premessage || ""} ${lessNo} ${value.postmessage || ""}`;
    }
    return value.message;
  };
  const extractStrongText = (text) => {
    const matches = text.match(/<strong>(.*?)<\/strong>/);
    return matches ? matches[1] : "";
  };

  return datas.map((val) => {
    return (
      val?.toggle == true && (
        <div className="card-container" style={{ 
          display: otherInventory && 
                   (val.stocklevel === "stocklevelone" || 
                    val.stocklevel === "stocklevelmore" || 
                    val.stocklevel === "stockleveloutofscope" || 
                    val.stocklevel === "stocklevelzero") 
                    ? "none" 
                    : "" ,
                  
                    borderRadius: "0.5rem",
                    backgroundColor: "#F2F2F7",
                    border: "2px solid #FF3B30",
                    padding: "0.5rem",
                    color: "#ffff",
        }}>


          {val.stock == "<=0" ? (
            <h4>Preview When Keep Selling but No Stock</h4>
          ) : (
            <h4>Preview when {extractStrongText(val.text)}</h4>
          )}
          <p style={{ color: val.color, fontSize: val.size }}>
            <span dangerouslySetInnerHTML={{ __html: val.icon }} />
            {computeMessage(val)}
          </p>
          <button  style={{  borderRadius: "0.5rem" }}>Add To Cart</button>
        </div>
      )
    );
  });
};

export default StockCart;
