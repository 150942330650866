import React, { useState, useEffect } from "react";
import { XSmallIcon } from "@shopify/polaris-icons";
import { Icon, AppProvider } from "@shopify/polaris";
import { Button } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import Swal from "sweetalert2";
import { fetchProducts } from "../services/shopifyService";
import "../App.css";

const Product = ({ set, shop, onStoreData, data, shopDetail }) => {
  const [products, setProducts] = useState([]);
  const [prods, setProds] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedProductIds, setSelectedProductIds] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const maxSelectionLimit = shopDetail.plan_id === 1 ? 4 : Infinity;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchProducts(shop, keyword);
        setProducts(response.products);
        console.log("Data retrieved successfully:", response.products);
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };

    fetchData();
  }, [shop, keyword]);

  useEffect(() => {
    console.log("Data prop received:", data);
    if (data) {
      try {
        console.log("Raw data before parsing:", data);

        // Handle different types of data
        let jsonString = "";
        if (typeof data === "string") {
          jsonString = data.trim();
        } else if (typeof data === "object") {
          // If data is an object, you may want to stringify it or access the relevant property
          jsonString = JSON.stringify(data); // Convert to string if it's an object
        } else {
          console.warn("Unexpected data type:", typeof data);
          setSelectedProductIds(new Set());
          return; // Exit if the type is unexpected
        }

        // Parse the JSON string
        const parsedData = JSON.parse(jsonString);
        if (Array.isArray(parsedData)) {
          const productIdsSet = new Set(parsedData.map((id) => String(id)));
          setSelectedProductIds(productIdsSet);
        } else {
          setSelectedProductIds(new Set());
        }
      } catch (error) {
        console.error("Error parsing JSON data:", error);
      }
    }
  }, [data]);

  const handleInputChange = (value) => {
    setKeyword(value);
  };

  // const handleCheckboxChange = (id) => {
  //   setSelectedProductIds((prevSelected) => {
  //     const newSelected = new Set(prevSelected);
  //     if (newSelected.has(String(id))) {
  //       newSelected.delete(String(id));
  //     } else {
  //       newSelected.add(String(id));
  //     }
  //     console.log("Checkbox changed, newSelected:", newSelected);
  //     return newSelected;
  //   });
  // };

  const handleCheckboxChange = (id) => {
    setSelectedProductIds((prevSelected) => {
      const newSelected = new Set(prevSelected);

      if (newSelected.has(String(id))) {
        newSelected.delete(String(id));
      } else if (newSelected.size < maxSelectionLimit) {
        newSelected.add(String(id));
      } else {
        Swal.fire({
          title: "Selection Limit Reached",
          text: "You can only select up to 4 products with your current plan.",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }

      return newSelected;
    });
  };

  const handleStoreData = () => {
    setLoading(true);

    const selectedProductIdsArray = Array.from(selectedProductIds);
    console.log("Selected product IDs to store:", selectedProductIdsArray);

    const jsonPayload = JSON.stringify(selectedProductIdsArray);

    if (onStoreData) {
      onStoreData(jsonPayload);
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <AppProvider>
      <div className="modal-container">
        <div className="modal-middle">
          <div className="modal-header">
            <h4>Add Products</h4>
            <span
              style={{ backgroundColor: "none", outline: "none" }}
              onClick={() => set(false)}
            >
              <Icon source={XSmallIcon} tone="base" />
            </span>
          </div>
          <div className="search-container">
            <input
              type="search"
              name="keyword"
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Search products"
            />
          </div>
          <div className="product-list">
            {products.length > 0 ? (
              products.map((product) => (
                <div key={product.id} className="search-row">
                  <input
                    type="checkbox"
                    id={product.id}
                    checked={selectedProductIds.has(String(product.id))}
                    onChange={() => handleCheckboxChange(String(product.id))}
                  />
                  <label htmlFor={product.id}>{product.title}</label>
                </div>
              ))
            ) : (
              <p style={{ padding: "20px" }}>No products found</p>
            )}
          </div>
          {loading && <div className="loading">Loading...</div>}
          <div className="modal-footer">
            <div>
              <p>{selectedProductIds.size} product(s) selected</p>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "14px!important" }}>
                <Button
                  variant="primary"
                  onClick={handleStoreData}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>
              <div style={{ marginRight: "14px!important" }}>
                <Button onClick={handleStoreData} disabled={loading}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppProvider>
  );
};

export default Product;
