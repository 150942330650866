import { getRoles } from "@testing-library/react";
import React, { useState, useRef, useEffect } from "react";
import Product from "../modals/Product";
import Collection from "../modals/Collection";
import { updateConditions, getConditions } from "../services/settingService";
import { fetchShopDetail } from "../services/shopifyService";
const Conditions = ({ shop, setOtherInventory }) => {
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shopDetail, setShopDetail] = useState([]);

  const [formData, setFormData] = useState({
    inventorySource: "inventory linked",
    minimumStockLevel: 2,
    maximumStockLevel: 9,
    productChecked: "",
    collectionChecked: "",
    widgetDisplay: "",
    widgetPosition: "above cart",
    stockColor: "#000000",
    positionAlignment: "",
    customCSS: "",
    hideStockLevel: false,
    shop_url: shop,
    products: [],
    collections: [],
  });
  const defaultWidgetDisplay =
    shopDetail.plan_id == 1 ? "Products" : formData.widgetDisplay;
  const colorInputRefs = useRef([]);
  const stripQuotes = (value) => {
    // Remove surrounding quotes if they exist
    return value.replace(/^"|"$/g, "");
  };

  const handleStoreData = (selectedProductIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: JSON.parse(selectedProductIds),
    }));
    console.log("Updated formData:", formData);
    setIsOpen(false);
  };

  const handleCollectionStoreData = (selectedCollectionIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      collections: JSON.parse(selectedCollectionIds),
    }));
    console.log("Updated formData handleCollectionStoreData:", formData);
    setIsOpen2(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchShopDetail(shop);
        setShopDetail(res.data);
        console.log("t", res.data);
        const response = await getConditions(shop);
        console.log("Data retrieved successfully1:", response.data);

        // Assuming response.data contains the relevant data for each field
        const data = response.data;
        console.log("d", data);

        if (data.inventorySource === "inventory linked")
          setOtherInventory(false);
        else setOtherInventory(true);

        // Update the formData state with values from the API response
        setFormData((prevFormData) => ({
          ...prevFormData,
          inventorySource: data.inventorySource || prevFormData.inventorySource,
          minimumStockLevel:
            data.minimumStockLevel || prevFormData.minimumStockLevel,
          maximumStockLevel:
            data.maximumStockLevel || prevFormData.maximumStockLevel,
          productChecked: data.productChecked || prevFormData.productChecked,
          collectionChecked:
            data.collectionChecked || prevFormData.collectionChecked,
          widgetDisplay: data.widgetDisplay || prevFormData.widgetDisplay,
          widgetPosition: data.widgetPosition || prevFormData.widgetPosition,
          stockColor: data.stockColor || prevFormData.stockColor,
          positionAlignment:
            data.positionAlignment || prevFormData.positionAlignment,
          customCSS: data.customCSS || prevFormData.customCSS,
          hideStockLevel:
            data.hideStockLevel !== undefined
              ? data.hideStockLevel
              : prevFormData.hideStockLevel,
          products: data.products || prevFormData.products,
          collections: data.collections || prevFormData.collections,
          shop_url: data.shop_url || prevFormData.shop_url,
        }));

        console.log("formData updated with API data:", formData);
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };

    fetchData();
  }, [shop]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === "inventorySource") {
      if (value === "inventory linked") setOtherInventory(false);
      else setOtherInventory(true);
    } else if (field === "positionAlignment") {
      setFormData((prevData) => ({
        ...prevData,
        customCSS: `text-align: ${value}`,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    console.log("Form Data:", formData);
    setTimeout(async () => {
      try {
        const response = await updateConditions(formData);
        console.log("Data submitted successfully:", response.data);
        setSuccessMessage(response.data.message);
        window.location.reload();
      } catch (error) {
        console.error("Error submitting data:", error);
        setErrorMessage("Error submitting data. Please try again.");
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  useEffect(() => {
    // Example of side effect, if needed
    console.log("Modal state changed:", isOpen);
  }, [isOpen]);
  return (
    <>
      <div style={{ border: "3px solid #F2F2F7", padding: "10px" }}>
        <h4 style={{ color: "#007f61", fontWeight: "600", fontSize: "20px" }}>
          Settings And Conditions
        </h4>
        <span>
          The System allows you to change the conditions and settings.
        </span>
      </div>

      <div>
        <h5 className="min-heading">Inventory Sources</h5>
        <select
          className="select-like-input"
          value={formData.inventorySource}
          onChange={(e) => handleInputChange("inventorySource", e.target.value)}
        >
          <option value="inventory linked">
            My inventory linked to Shopify
          </option>
          <option value="inventory not linked">
            Not Using Shopify for inventory
          </option>
        </select>
      </div>
      {formData.inventorySource === "inventory linked" ? (
        <p className="select-bottom-para">
          The widget displays the stock level you have on Shopify.
        </p>
      ) : (
        <>
          <p className="select-bottom-para">
            The widget displays the stock level based on your input. Please make
            your changes for this message on <strong>"Low in stock"</strong>
            section.
          </p>
          <p className="select-bottom-para">
            My inventory for each variant is between:
          </p>
          <div className="rang-stock-level">
            <span>
              Minimum stock level:{" "}
              <input
                type="number"
                style={{ marginLeft: "10px" }}
                value={formData.minimumStockLevel}
              />
            </span>
            <span style={{ marginLeft: "30px" }}>
              Maximum stock level:{" "}
              <input
                type="number"
                style={{ marginLeft: "10px" }}
                value={formData.maximumStockLevel}
              />
            </span>
          </div>
        </>
      )}

      <div className="widget-row">
        <div>
          <h5 className="min-heading">Display Widget On:</h5>
          <select
            className="select-like-input"
            value={defaultWidgetDisplay}
            onChange={(e) => handleInputChange("widgetDisplay", e.target.value)}
          >
            <option value="" disabled={shopDetail.plan_id === 1}>
              All Products And Collection
            </option>
            <option value="Products" selected={shopDetail.plan_id === 1}>
              Choose by Products
            </option>
            <option value="Collections" disabled={shopDetail.plan_id === 1}>
              Choose by Collections
            </option>
          </select>
        </div>

        <div>
          <h5 className="min-heading">Widget Position:</h5>
          <select
            className="select-like-input"
            value={formData.widgetPosition}
            onChange={(e) =>
              handleInputChange("widgetPosition", e.target.value)
            }
          >
            <option value="above cart">Above Add To Cart</option>
            <option value="below cart">Below Add To Cart</option>
          </select>
        </div>
      </div>

      {formData.widgetDisplay === "Products" || shopDetail.plan_id == 1 ? (
        <div className="rang-stock-level" style={{ marginTop: "20px" }}>
          <label>
            <input
              type="radio"
              name="productFilter"
              value="include"
              checked={stripQuotes(formData.productChecked) == "include"}
              onClick={() => {
                setIsOpen(true); // Open the modal
                handleInputChange("productChecked", "include");
              }}
            />
            Include Selected Product
          </label>
          {shopDetail.plan_id !== 1 && (
            <label style={{ marginLeft: "30px" }}>
              <input
                type="radio"
                name="productFilter"
                value="exclude"
                checked={stripQuotes(formData.productChecked) == "exclude"}
                onClick={() => {
                  setIsOpen(true);
                  handleInputChange("productChecked", "exclude");
                }}
              />
              Exclude Selected Product
            </label>
          )}
        </div>
      ) : formData.widgetDisplay === "Collections" ? (
        <div className="rang-stock-level" style={{ marginTop: "20px" }}>
          <label>
            <input
              type="radio"
              name="collectiontFilter"
              value="include"
              checked={stripQuotes(formData.collectionChecked) === "include"}
              onClick={() => {
                setIsOpen2(true);
                handleInputChange("collectionChecked", "include");
              }}
            />
            Include Selected Collection
          </label>
          <label style={{ marginLeft: "30px" }}>
            <input
              type="radio"
              name="collectiontFilter"
              value="exclude"
              checked={stripQuotes(formData.collectionChecked) === "exclude"}
              onClick={() => {
                setIsOpen2(true);
                handleInputChange("collectionChecked", "exclude");
              }}
            />
            Exclude Selected Collection
          </label>
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: "30px" }}>
        <p
          className="advance-settion"
          onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
        >
          Advance Settings
        </p>
      </div>

      {showAdvanceSettings && (
        <div>
          <div className="widget-row">
            <div>
              <h5 className="min-heading">
                Stock level color in low stock message
              </h5>

              <div className="color">
                <input
                  type="color"
                  value={formData.stockColor}
                  style={{ display: "none" }}
                  ref={(el) => (colorInputRefs.current[0] = el)}
                  onChange={(e) =>
                    handleInputChange("stockColor", e.target.value)
                  }
                />
                <span
                  className="color-box"
                  style={{
                    backgroundColor: formData.stockColor,
                    cursor: "pointer",
                  }}
                  onClick={() => colorInputRefs.current[0]?.click()}
                ></span>
                <span>
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={formData.stockColor}
                    onChange={(e) =>
                      handleInputChange("stockColor", e.target.value)
                    }
                  />
                </span>
              </div>
            </div>
            <div>
              <h5 className="min-heading">Position Alignment</h5>
              <select
                value={formData.positionAlignment}
                className="select-like-input"
                onChange={(e) =>
                  handleInputChange("positionAlignment", e.target.value)
                }
              >
                <option value="">Select Alignment</option>
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </div>
          </div>
          <div className="widget-row">
            <div>
              <input
                type="checkbox"
                checked={formData.hideStockLevel}
                onChange={(e) =>
                  handleInputChange("hideStockLevel", e.target.checked)
                }
              />
              <label>Hide the stock level in low stock message</label>
            </div>

            <div>
              <h5 className="min-heading">Custom CSS</h5>
              <div>
                <textarea
                  value={formData.customCSS}
                  onChange={(e) =>
                    handleInputChange("customCSS", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          textAlign: "right",
        }}
      >
        <button
          className="primary"
          style={{
            width: "8rem",
            borderRadius: "0.5rem",
            backgroundColor: "#047857",
            padding: "0.5rem",
            color: "#ffff",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save Settings"}
        </button>
      </div>

      {loading && <p className="loading">Loading...</p>}

      {successMessage && <p className="success-message">{successMessage}</p>}

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {isOpen && (
        <Product
          set={setIsOpen}
          shop={shop}
          onStoreData={handleStoreData}
          data={formData.products}
          shopDetail={shopDetail}
        />
      )}
      {isOpen2 && (
        <Collection
          set={setIsOpen2}
          shop={shop}
          onStoreData={handleCollectionStoreData}
          data={formData.collections}
        />
      )}
    </>
  );
};

export default Conditions;
