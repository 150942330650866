import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ShopifyCallback from './components/ShopifyCallback';
import InstallPage from './components/InstallPage';
import AppViewPage from './components/AppViewPage';
import PlanViewPage from './components/PlanViewPage';
import SettingViewPage from './components/SettingViewPage';
import UpdateChargePage from './components/UpdateChargePage';
import GdprViewCustomer from './components/GdprViewCustomer';
import HowToUse from './components/HowToUse';
import ShopifyAuth from "./components/ShopifyAuth";
import Unauthorized from "./components/Unauthorized";

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    // Remove Shopify elements only on the `/shopify/install` route
    if (location.pathname === '/shopify/install') {
      const metaTag = document.querySelector('meta[name="shopify-api-key"]');
      const scriptTag = document.querySelector('script[src="https://cdn.shopify.com/shopifycloud/app-bridge.js"]');
      
      if (metaTag) document.head.removeChild(metaTag);
      if (scriptTag) document.head.removeChild(scriptTag);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/shopify/callback" element={<ShopifyCallback />} />
      <Route path="/shopify/install" element={<InstallPage />} />
      <Route path="/shopify/app_view" element={<AppViewPage />} />
      <Route path="/shopify/plan_view" element={<PlanViewPage />} />
      <Route path="/shopify/setting_view" element={<SettingViewPage />} />
      <Route path="/shopify/updateCharge" element={<UpdateChargePage />} />
      <Route path="/shopify/guide_view" element={<HowToUse />} />
      <Route path="/gdpr_view_customer" element={<ShopifyAuth element={<GdprViewCustomer />} />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
